import {Injectable} from '@angular/core';
import {QuoteOrderSampleModel, RequestParamModel} from '../models';
import {PaginationModel} from '../models';
import {BaseService} from '../services';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {QuoteOrderSampleStatusModel} from "../models/quote-order-sample-status-model";
import {HttpErrorResponse} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class QuoteOrderSampleService {

  constructor(private baseService: BaseService) {
  }

  // get all quote/order list
  getQuoteAndOrders(requestParamModel: RequestParamModel): any {
    return this.baseService.get('quote-order', requestParamModel)
      .pipe(map<any, any>(response => {
        const accountList = new PaginationModel<QuoteOrderSampleModel>();
        const headers = response.headers;
        accountList.setHeaders(headers);
        accountList.data = response.body.data as QuoteOrderSampleModel[];
        return accountList;
      }, (err: any) => {
        return err;
      }));
  }

  // get single quote/order details.
  getQuoteData(accountId: number, requestParamModel: RequestParamModel): Observable<QuoteOrderSampleModel> {
    return this.baseService.get('quote-order/' + accountId, requestParamModel)
      .pipe(map<any, QuoteOrderSampleModel>(response => {
        return response.body.data as QuoteOrderSampleModel;
      }, (err: any) => {
        return err;
      }));
  }

  // save user if new then add if old then update
  createQuoteOrder(accountModel: any): Observable<number> {
    if (accountModel.id !== undefined) {
      return this.baseService.patch('quote-order/' + accountModel.id, accountModel)
        .pipe(map<any, number>(response => {
            accountModel = response.data as QuoteOrderSampleModel;
            return accountModel;
          }, (err: any) => {
            return err;
          })
        );
    } else {
      return this.baseService.post('quote-order', accountModel)
        .pipe(map<any, number>(response => {
            accountModel = response.data as QuoteOrderSampleModel;
            return accountModel;
          }, (err: any) => {
            return err;
          })
        );
    }
  }

  saveAsDraftQuoteOrder(accountModel: any): Observable<number> {
    if (accountModel.id !== undefined) {
      return this.baseService.post('quote-order/save-as-draft/' + accountModel.id, accountModel)
        .pipe(map<any, number>(response => {
            accountModel = response.data as QuoteOrderSampleModel;
            return accountModel;
          }, (err: any) => {
            return err;
          })
        );
    } else {
      return this.baseService.post('quote-order/save-as-draft', accountModel)
        .pipe(map<any, number>(response => {
            accountModel = response.data as QuoteOrderSampleModel;
            return accountModel;
          }, (err: any) => {
            return err;
          })
        );
    }
  }

  saveWareHouseDate(body: any) {
    return this.baseService.post('quote-order/warehouse-date', body).pipe(map(response => {
      return response;
    }, ((error: any) => {
      return error;
    })))
  }

  quoteArchive(body: any): Observable<any> {
    return this.baseService.post('quote-order/archive', body).pipe(map(response => {
      return response
    }, (err: any) => {
      return err;
    }))
  }

  orderCancel(body: any): Observable<any> {
    return this.baseService.post('quote-order/archive', body).pipe(map(response => {
      return response
    }, (err: any) => {
      return err;
    }))
  }

  // save user if new then add if old then update
  saveQuoteProduct(accountModel: any): Observable<number> {
    return this.baseService.post('quote-product/create', accountModel)
      .pipe(map<any, number>(response => {
          accountModel = response.data as any;
          return accountModel.id;
        }, (err: any) => {
          return err;
        })
      );
  }

  saveQuoteProductAppliedCalculator(quoteProductAppliedCalculatorModel: any): Observable<number> {
    return this.baseService.post('quote-product-applied-calculator/create', quoteProductAppliedCalculatorModel)
      .pipe(map<any, number>(response => {
          quoteProductAppliedCalculatorModel = response.data as any;
          return quoteProductAppliedCalculatorModel.id;
        }, (err: any) => {
          return err;
        })
      );
  }

  deleteQuoteProductAppliedCalculator(_id: any) {
    return this.baseService.delete('quote-product-applied-calculator/' + _id)
      .pipe(map<any, number>(response => {
          return response;
        }, (err: any) => {
          return err;
        })
      );
  }

  // save user if new then add if old then update
  sampleAddUnleashed(bodyData: any): Observable<number> {
    return this.baseService.post('quote-order/pushed-sample-order-unleashed', bodyData)
      .pipe(map<any, number>(response => {
          return response;
        }, (err: any) => {
          return err;
        })
      );
  }

  changeStatus(orderModel: any): Observable<number> {
    if (orderModel.id !== undefined) {
      return this.baseService.patch('quote-order/status-update/' + orderModel.id, orderModel)
        .pipe(map<any, number>(response => {
            orderModel = response.data as QuoteOrderSampleModel;
            return orderModel.id;
          }, (err: any) => {
            return err;
          })
        );
    } else {
      return this.baseService.post('quote-order/status-update', orderModel)
        .pipe(map<any, number>(response => {
            orderModel = response.data as QuoteOrderSampleModel;
            return orderModel.id;
          }, (err: any) => {
            return err;
          })
        );
    }
  }

  addDeliveryCharge(orderModel: any): Observable<number> {
    return this.baseService.patch('quote-order/add-delivery-charge/' + orderModel.id, orderModel)
      .pipe(map<any, number>(response => {
          orderModel = response.data as QuoteOrderSampleModel;
          return orderModel.id;
        }, (err: any) => {
          return err;
        })
      );
  }

  deleteTrackingNumber(id: any) {
    return this.baseService.delete('sample-tracking-number/' + id)
      .pipe(map<any, number>(response => {
          return response;
        }, (err: any) => {
          return err;
        })
      );
  }

  deleteAllTackingNumbers(id: any) {
    return this.baseService.delete('sample-tracking-number/delete-all/' + id)
      .pipe(map<any, number>(response => {
          return response;
        }, (err: any) => {
          return err;
        })
      );
  }


  addTrackingNumber(orderModel: any): Observable<number> {
    return this.baseService.post('sample-tracking-number', orderModel)
      .pipe(map<any, number>(response => {
          orderModel = response.data as QuoteOrderSampleModel;
          return orderModel.id;
        }, (err: any) => {
          return err;
        })
      );
  }


  // save user if new then add if old then update
  saveAccountAddress(accountModel: any): Observable<number> {
    return this.baseService.post('quote-orders', accountModel)
      .pipe(map<any, number>(response => {
          accountModel = response.data as QuoteOrderSampleModel;
          return accountModel.id;
        }, (err: any) => {
          return err;
        })
      );
  }

  deleteProduct(_id: any) {
    return this.baseService.delete('quote-product/' + _id)
      .pipe(map<any, number>(response => {
          return response;
        }, (err: any) => {
          return err;
        })
      );
  }

  deleteAccountAddress(_id: any) {
    return this.baseService.delete('quote-orders/' + _id)
      .pipe(map<any, number>(response => {
          return response;
        }, (err: any) => {
          return err;
        })
      );
  }

  // get single quote/order details.
  quotePaymentStatusListing(requestParamModel: RequestParamModel): Observable<any> {
    return this.baseService.get('quote-payment', requestParamModel)
      .pipe(map<any, any>(response => {
        return response.body as [];
      }, (err: any) => {
        return err;
      }));
  }

  // save user if new then add if old then update
  saveShippingPrice(accountModel: any): Observable<number> {
    return this.baseService.patch('shipping-update/' + accountModel.id, accountModel)
      .pipe(map<any, number>(response => {
          accountModel = response.data as any;
          return accountModel.id;
        }, (err: any) => {
          return err;
        })
      );
  }

  getTruckDetail(requestParamModel: any) {
    return this.baseService.get('shipping-truck-type', requestParamModel)
      .pipe(map<any, any>(response => {
        return response.body.data as any;
      }, (err: any) => {
        return err;
      }));
  }

  getShippingCost(requestParamModel: any) {
    return this.baseService.post('quote-order/shipping-calculation', requestParamModel)
      .pipe(map<any, any>(response => {
        return response.data as any;
      }, (err: any) => {
        return err;
      }));
  }

  quoteExportPDF(idQuote: number, formData: any) {
    return this.baseService.uploadPost('quote-order/export/' + idQuote, formData).pipe(map<any, any>(response => {
      return response.data as any;
    }, (err: any) => {
      return err;
    }))
  }

  exportTrackingLinkPDF(id: number, formData: any) {
    return this.baseService.uploadPost('quote-order/export-tracking-link/' + id, formData).pipe(map<any, any>(response => {
      return response.data as any;
    }, (err: any) => {
      return err;
    }))
  }

  exportConsumableSummaryPDF(id: number, formData: any) {
    return this.baseService.uploadPost('quote-order/export-consumable-summary/' + id, formData).pipe(map<any, any>(response => {
      return response.data as any;
    }, (err: any) => {
      return err;
    }))
  }

  deleteQuoteProducts(_id: any) {
    return this.baseService.delete('quote-product/' + _id);
  }

  bigPostJobCreate(requestData: any) {
    return this.baseService.post('quote-order/push-at-big-post', requestData)
      .pipe(map<any, any>(response => {
        return response.data as any;
      }, (err: any) => {
        return err;
      }))
  }

  sendMailForNominateDate(body: any) {
    return this.baseService.post('quote-order/send-mail', body).pipe(
      map(response => {
        return response
      }, (error: any) => {
        return error;
      })
    )
  }

  sendMail(body: any) {
    return this.baseService.post('quote-order/send-mail', body).pipe(
      map(response => {
        return response;
      }, (err: any) => {
        return err;
      })
    )
  }

  updateDeliveryAddress(orderModel: any): Observable<number> {
    return this.baseService.patch('quote-order/delivery-address-update/' + orderModel.quote_id, orderModel)
      .pipe(map<any, number>(response => {
          orderModel = response.data as QuoteOrderSampleModel;
          return orderModel.id;
        }, (err: any) => {
          return err;
        })
      );
  }

  // download product sizing table
  generatesampleLabel(orderId: number): any {
    return this.baseService.get('quote-order/generate-sample-label/' + orderId)
      .pipe(map<any, any>(response => {
        return response.body.data;
      }, (err: any) => {
        return err;
      }));
  }

  generateCrateLabel(orderId: number): any {
    return this.baseService.get('quote-order/generate-crate-label/' + orderId)
      .pipe(map<any, any>(response => {
        return response.body.data;
      }, (err: any) => {
        return err;
      }));
  }

  deleteQuoteOrder(_id: any) {
    return this.baseService.delete('quote-order/' + _id)
      .pipe(map<any, number>(response => {
        return response;
      }, (err: any) => {
        return err;
      }));
  }

  getNote(requestModel: any) {
    return this.baseService.get('note', requestModel)
      .pipe(map<any, any>(response => {
          return response.body.data as any;
        }, (err: any) => {
          return err;
        })
      );
  }

  addUpdateNote(requestModel: any) {
    if (requestModel.noteId) {
      return this.baseService.patch('note/' + requestModel.noteId, requestModel)
        .pipe(map<any, number>(response => {
            requestModel = response.data as QuoteOrderSampleModel;
            return requestModel;
          }, (err: any) => {
            return err;
          })
        );
    } else {
      return this.baseService.post('note', requestModel)
        .pipe(map<any, number>(response => {
            requestModel = response.data as QuoteOrderSampleModel;
            return requestModel;
          }, (err: any) => {
            return err;
          })
        );

    }
  }

  addUpdateNoteWithAttachment(requestModel: any, noteId?: any) {
    if (noteId) {
      return this.baseService.uploadPost(`note/upload` + '/' + noteId, requestModel).pipe(
        map<any, any>(response => {
          return response.data;
        }, (error: any) => {
          return error;
        })
      )
    } else {
      return this.baseService.uploadPost(`note`, requestModel).pipe(
        map<any, any>(response => {
          return response.data;
        }, (error: any) => {
          return error;
        })
      )
    }
  }

  downloadQuoteOrder(param: any) {
    return this.baseService.post('quote-order/download', param)
      .pipe(map<any, any>(response => {
        return response.data as any;
      }, (err: any) => {
        return err;
      }));
  }

  downloadQuoteOrderNew(body: any) {
    return this.baseService.getDownloadFileContent(`quote-order/download-file?file_name=${body.file_name}`);
  }

  shareQuoteOrder(param: any) {
    return this.baseService.post('quote-order/share', param)
      .pipe(map<any, any>(response => {
        return response.data as any;
      }, (err: any) => {
        return err;
      }));
  }

  getShippingRegions(requestParamModel: RequestParamModel): any {
    return this.baseService.get('shipping-region', requestParamModel)
      .pipe(map<any, any>(response => {
        const regionList = new PaginationModel<QuoteOrderSampleModel>();
        const headers = response.headers;
        regionList.setHeaders(headers);
        regionList.data = response.body.data as QuoteOrderSampleModel[];
        return regionList;
      }, (err: any) => {
        return err;
      }));
  }

  getCarrierStatus(requestParamModel: RequestParamModel): any {
    return this.baseService.get('carrier-status', requestParamModel)
      .pipe(map<any, any>(response => {
        const carrierStatusList = new PaginationModel<QuoteOrderSampleModel>();
        const headers = response.headers;
        carrierStatusList.setHeaders(headers);
        carrierStatusList.data = response.body.data as QuoteOrderSampleModel[];
        return carrierStatusList;
      }, (err: any) => {
        return err;
      }));
  }

  updateQuoteOrder(orderModel: any): Observable<number> {
    return this.baseService.patch('quote-order/' + orderModel.id, orderModel)
      .pipe(map<any, number>(response => {
          orderModel = response.data as QuoteOrderSampleModel;
          return orderModel.id;
        }, (err: any) => {
          return err;
        })
      );
  }

  updateGPSMReference(orderModel: any): Observable<number> {
    return this.baseService.patch('quote-order/update-gpsm-reference/' + orderModel.id, orderModel)
      .pipe(map<any, number>(response => {
          orderModel = response.data as QuoteOrderSampleModel;
          return orderModel.id;
        }, (err: any) => {
          return err;
        })
      );
  }

  updateQuoteOrderdetails(orderModel: any, data: any): Observable<number> {
    return this.baseService.patch('quote-order/' + orderModel, data)
      .pipe(map<any, number>((response) => {
          orderModel = response.data as QuoteOrderSampleModel;
          return orderModel.id;
        }, (err: any) => {
          return err;
        })
      );
  }

  reactivateQuote(requestData: any) {
    return this.baseService.post('quote-order/reactivate-quote', requestData)
      .pipe(map<any, any>(response => {
        return response.data as any;
      }, (err: any) => {
        return err;
      }))
  }

  deleteNote(_id: any) {
    return this.baseService.delete('note/' + _id)
      .pipe(map<any, number>(response => {
          return response;
        }, (err: any) => {
          return err;
        })
      );
  }

  getCounter(requestParamModel: RequestParamModel) {
    return this.baseService.get('quote-order/counter', requestParamModel)
      .pipe(map<any, any>(response => {
        return response.body.data as any;
      }, (err: any) => {
        return err;
      }));
  }

  getTotalSampleProductCounter(requestParamModel: RequestParamModel) {
    return this.baseService.get('quote-order/total-sample-product-counter', requestParamModel)
      .pipe(map<any, any>(response => {
        return response.body.data as any;
      }, (err: any) => {
        return err;
      }));
  }

  confirmCarrierJob(requestBody: any): any {
    return this.baseService.post('quote-order/confirm-job', requestBody)
      .pipe(map<any, any>(response => {
        return response.data as any;
      }, (err: any) => {
        return err;
      }));
  }

  reCalculateQuotePrice(quoteModel: any): Observable<number> {
    return this.baseService.post('quote-order/re-calculate-quote-price', quoteModel)
      .pipe(map<any, number>(response => {
          quoteModel = response.data as any;
          return quoteModel.id;
        }, (err: any) => {
          return err;
        })
      );
  }

  deleteAllQuoteProductAppliedCalculators(_id: any) {
    return this.baseService.delete('quote-order/delete-all-quote-product-applied-calculators/' + _id)
      .pipe(map<any, number>(response => {
          return response;
        }, (err: any) => {
          return err;
        })
      );
  }

  generateExportId(id: any, requestParamModel: RequestParamModel) {
    return this.baseService.get('quote-order/generate-export-id/' + id, requestParamModel)
      .pipe(map<any, any>(response => {
        return response.body.data as any;
      }, (err: any) => {
        return err;
      }));
  }


  downloadFileContent(bodyData: any) {
    return this.baseService.getDownloadFileContent(`note/download?id=${bodyData.id}`);
  }


  deleteSelectedPdfFile(_id: any) {
    return this.baseService.delete('note/delete-attachment/' + _id)
      .pipe(map<any, number>(response => {
          return response;
        }, (err: any) => {
          return err;
        })
      );
  }

  previewUploadFile(id: any) {
    return this.baseService.get('note/preview/' + id)
      .pipe(map<any, any>(response => {
        return response.body.data as any;
      }, (err: any) => {
        return err;
      }));
  }

  getWarehouseStatus(requestParamModel: RequestParamModel): any {
    return this.baseService.get('warehouse-status', requestParamModel)
      .pipe(map<any, any>(response => {
        const warehouseStatusList = new PaginationModel<QuoteOrderSampleModel>();
        const headers = response.headers;
        warehouseStatusList.setHeaders(headers);
        warehouseStatusList.data = response.body.data as QuoteOrderSampleModel[];
        return warehouseStatusList;
      }, (err: any) => {
        return err;
      }));
  }

  getOrderPackingSlip(requestModel: any) {
    return this.baseService.get('order-packing-slip', requestModel)
      .pipe(map<any, any>(response => {
          return response.body.data as any;
        }, (err: any) => {
          return err;
        })
      );
  }

  addPackingSlip(requestModel: any) {
    return this.baseService.uploadPost(`order-packing-slip`, requestModel).pipe(
      map<any, any>(response => {
        return response.body;
      }, (error: any) => {
        return error;
      })
    )
  }

  downloadPackingSlip(bodyData: any) {
    return this.baseService.getDownloadFileContent(`order-packing-slip/download?id=${bodyData.id}`);
  }

  previewPackingSlip(id: any) {
    return this.baseService.get('order-packing-slip/preview/' + id)
      .pipe(map<any, any>(response => {
        return response.body.data as any;
      }, (err: any) => {
        return err;
      }));
  }

  deleteSelectedPackingSlip(_id: any) {
    return this.baseService.delete('order-packing-slip/delete/' + _id)
      .pipe(map<any, number>(response => {
          return response;
        }, (err: any) => {
          return err;
        })
      );
  }

  getPartialPaymentReceipt(requestModel: any) {
    return this.baseService.get('quote-order-payment-receipt', requestModel)
      .pipe(map<any, any>(response => {
          return response.body.data as any;
        }, (err: any) => {
          return err;
        })
      );
  }

  addPartialPaymentReceipt(requestModel: any) {
    return this.baseService.uploadPost(`quote-order-payment-receipt`, requestModel).pipe(
      map<any, any>(response => {
        return response.body;
      }, (error: any) => {
        return error;
      })
    )
  }

  downloadPartialPaymentReceipt(bodyData: any) {
    return this.baseService.getDownloadFileContent(`quote-order-payment-receipt/download?id=${bodyData.id}`);
  }

  downloadGPSMReferenceAttachment(bodyData: any) {
    return this.baseService.getDownloadFileContent(`quote-order/download-gpsm-reference-file?id=${bodyData.id}&gpsm_reference=${bodyData.gpsm_reference}`);
  }

  downloadPackegingSlip(bodyData: any) {
    return this.baseService.getDownloadFileContent(`order-packing-slip/download?id=${bodyData.id}`);
  }

  previewPartialPaymentReceipt(id: any) {
    return this.baseService.get('quote-order-payment-receipt/preview/' + id)
      .pipe(map<any, any>(response => {
        return response.body.data as any;
      }, (err: any) => {
        return err;
      }));
  }

  deleteSelectedPartialPaymentReceipt(_id: any) {
    return this.baseService.delete('quote-order-payment-receipt/delete/' + _id)
      .pipe(map<any, number>(response => {
          return response;
        }, (err: any) => {
          return err;
        })
      );
  }

  getConsumableDetail(requestParamModel: RequestParamModel): any {
    return this.baseService.get('quote-product-applied-calculator', requestParamModel)
      .pipe(map<any, any>(response => {
        const regionList = new PaginationModel<QuoteOrderSampleModel>();
        const headers = response.headers;
        regionList.setHeaders(headers);
        regionList.data = response.body.data as any;
        return regionList;
      }, (err: any) => {
        return err;
      }));
  }

  getQuoteOrderSampleStatus(requestParamModel: RequestParamModel): any {
    return this.baseService.get('quote-status', requestParamModel)
      .pipe(map<any, any>(response => {
        const quoteOrderSampleStatusList = new PaginationModel<QuoteOrderSampleStatusModel>();
        const headers = response.headers;
        quoteOrderSampleStatusList.setHeaders(headers);
        quoteOrderSampleStatusList.data = response.body.data as QuoteOrderSampleStatusModel[];
        return quoteOrderSampleStatusList;
      }, (err: any) => {
        return err;
      }));
  }

  linkQuoteOrder(linkQuoteOrderModel: any): Observable<any> {
    return this.baseService.post('link-quote-order', linkQuoteOrderModel)
      .pipe(map<any, number>(response => {
          linkQuoteOrderModel = response as any;
          return linkQuoteOrderModel;
        }, (err: any) => {
          return err;
        })
      );
  }

  deleteLinkedQuoteOrder(_id: any) {
    return this.baseService.delete('link-quote-order/' + _id)
      .pipe(map<any, number>(response => {
          return response;
        }, (err: any) => {
          return err;
        })
      );
  }

  updateDatesForProductionMilestone(requestModel: any): Observable<QuoteOrderSampleModel> {
    return this.baseService.post('quote-order/update-dates-for-production-milestone', requestModel)
      .pipe(
        map((response: any) => {
          const quoteOrderModel = response.data as QuoteOrderSampleModel;
          return quoteOrderModel;
        }),
        catchError((err: HttpErrorResponse) => {
          // Handle the error appropriately here
          throw err;
        })
      );
  }

  linkRemittanceWithPayment(linkRemittanceWithPaymentModel: any): Observable<any> {
    return this.baseService.post('quote-order/link-remittance-with-payment', linkRemittanceWithPaymentModel)
      .pipe(map<any, number>(response => {
          linkRemittanceWithPaymentModel = response.data as any;
          return linkRemittanceWithPaymentModel;
        }, (err: any) => {
          return err;
        })
      );
  }

  addUpdateQuoteProductComment(quoteProductCommentModel: any, productId: any): Observable<any> {
    return this.baseService.patch('quote-product/' + productId, quoteProductCommentModel)
      .pipe(map<any, number>(response => {
          quoteProductCommentModel = response.data as any;
          return quoteProductCommentModel;
        }, (err: any) => {
          return err;
        })
      );
  }

  sendNotification(requestModel: any) {
    return this.baseService.uploadPost(`notification`, requestModel).pipe(
      map<any, any>(response => {
        return response.body;
      }, (error: any) => {
        return error;
      })
    )
  }


  uploadSamplePackingImage(body: any): Observable<any> {
    return this.baseService.post('sample-packing-image', body).pipe(map((response: any) => {
      return response.data as any;
    }, ((error: any) => {
      return error;
    })))
  }

  deleteSamplePackingImage(_id: any) {
    return this.baseService.delete('sample-packing-image/' + _id)
      .pipe(map<any, number>(response => {
          return response;
        }, (err: any) => {
          return err;
        })
      );
  }

  updateExistingTender(data) {
    return this.baseService.post('quote-order/update-existing-tenders', data).pipe(map((response: any) => {
      return response.data as any;
    }, ((error: any) => {
      return error;
    })))

  }
}
